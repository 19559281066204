import { StaffUser, StaffUserRole } from "@vaultinum/vaultinum-api";
import { ReactNode } from "react";
import {
    ABSOLUTE_KYS1_PAGE,
    ACCOUNTS_PAGE,
    DEPOSIT_PAGE,
    DOMAINS_PAGE,
    ESCROW_PAGE,
    EVENTS_PAGE,
    FULL_AUDIT_PAGE,
    KYS2_PAGE,
    PROMOTIONS_PAGE,
    STAFF_USERS_PAGE,
    USER_PROFILES_PAGE
} from "../services";

export enum AppName {
    VAULTINUM = "VAULTINUM",
    EVIDENCY = "EVIDENCY"
}

type MenuConfig = {
    key: string;
    path: string;
    label: string;
    visibleIn: AppName[];
    roles: StaffUserRole[];
};

export const menuConfig: MenuConfig[] = [
    {
        key: "staff-users",
        path: STAFF_USERS_PAGE,
        label: "Staff Users",
        visibleIn: [AppName.VAULTINUM, AppName.EVIDENCY],
        roles: [StaffUserRole.ADMIN]
    },
    {
        key: "user-profiles",
        path: USER_PROFILES_PAGE,
        label: "User Profiles",
        visibleIn: [AppName.VAULTINUM, AppName.EVIDENCY],
        roles: [StaffUserRole.CLIENT_SERVICE]
    },
    {
        key: "accounts",
        path: ACCOUNTS_PAGE,
        label: "Organisations",
        visibleIn: [AppName.VAULTINUM, AppName.EVIDENCY],
        roles: [StaffUserRole.ADMIN, StaffUserRole.CLIENT_SERVICE]
    },
    {
        key: "domains",
        path: DOMAINS_PAGE,
        label: "Domains",
        visibleIn: [AppName.VAULTINUM, AppName.EVIDENCY],
        roles: [StaffUserRole.ADMIN]
    },
    {
        key: "events",
        path: EVENTS_PAGE,
        label: "Events",
        visibleIn: [AppName.VAULTINUM],
        roles: [StaffUserRole.ADMIN, StaffUserRole.CLIENT_SERVICE]
    },
    {
        key: "promotions",
        path: PROMOTIONS_PAGE,
        label: "Promotions",
        visibleIn: [AppName.VAULTINUM],
        roles: [StaffUserRole.ADMIN]
    },

    {
        key: "deposit",
        path: DEPOSIT_PAGE,
        label: "IP Deposit",
        visibleIn: [AppName.VAULTINUM],
        roles: [StaffUserRole.CLIENT_SERVICE]
    },
    {
        key: "escrow",
        path: ESCROW_PAGE,
        label: "Escrow",
        visibleIn: [AppName.VAULTINUM],
        roles: [StaffUserRole.CLIENT_SERVICE]
    },
    {
        key: "kys1",
        path: ABSOLUTE_KYS1_PAGE,
        label: "KYS - Survey Editor",
        visibleIn: [AppName.VAULTINUM],
        roles: [StaffUserRole.KYS1_REVIEWER]
    },
    {
        key: "kys2",
        path: KYS2_PAGE,
        label: "KYS - Code Audit",
        visibleIn: [AppName.VAULTINUM],
        roles: [StaffUserRole.ADMIN]
    },
    {
        key: "full-audit",
        path: FULL_AUDIT_PAGE,
        label: "KYS - Due Diligence",
        visibleIn: [AppName.VAULTINUM],
        roles: [StaffUserRole.KYS2_REVIEWER, StaffUserRole.CLIENT_SERVICE]
    }
];

export function getMenuItemsPerBrand(
    addMenuItem: (
        key: string,
        to: string,
        children: ReactNode,
        staffUser?: StaffUser | null,
        requiredRoles?: StaffUserRole[]
    ) => {
        key: string;
        label: JSX.Element;
    } | null,
    staffUser: StaffUser | null
) {
    return menuConfig
        .filter(item => item.visibleIn.includes((process.env.REACT_APP_BRAND_NAME || AppName.VAULTINUM) as AppName))
        .map(item => addMenuItem(item.key, item.path, item.label, staffUser, item.roles));
}
