import { Account, FullAudit, StaffUser } from "@vaultinum/vaultinum-api";
import { Button, Column, formatDate, formatDateTime, Table, Tag } from "@vaultinum/vaultinum-sdk";
import { useNavigate } from "react-router-dom";
import { FULL_AUDIT_DETAIL_PAGE } from "../../../services/routing.service";

function getFullAuditColumns(accountId: string, redirectToFullAudit: (fullAuditId: string) => void): Column<FullAudit>[] {
    return [
        {
            header: "Creation date",
            accessorKey: "creationDate",
            cell: cell => formatDateTime(cell.getValue<Date>())
        },
        {
            header: "Target Completion Date",
            accessorKey: "targetCompletionDate",
            cell: cell => {
                const targetCompletionDate = cell.getValue<Date>();
                return targetCompletionDate ? formatDate(targetCompletionDate) : "-";
            }
        },
        {
            header: "Completion Date",
            accessorKey: "completionDate",
            cell: cell => {
                const completionDate = cell.getValue<Date>();
                return completionDate ? formatDate(completionDate) : "-";
            }
        },
        {
            header: "Product name",
            accessorFn: row => row.product?.name
        },
        {
            header: "Audited / Beneficiary",
            enableColumnFilter: false,
            enableSorting: false,
            accessorFn: row => row.roles,
            cell: cell => {
                const fullAudit = cell.row.original;
                return (
                    <div className="flex flex-col gap-1">
                        {fullAudit.roles[accountId].includes(FullAudit.Role.AUDITED) && <Tag type="info" message="🧑‍💻 Audited" />}
                        {fullAudit.roles[accountId].includes(FullAudit.Role.BENEFICIARY) && <Tag type="info" message="🔖 Beneficiary" isOutlined />}
                    </div>
                );
            }
        },
        {
            header: "Scopes",
            accessorKey: "scopes",
            cell: cell => (
                <div className="flex items-center space-x-1 space-y-1">{cell.getValue<string[]>()?.map(scope => <Tag key={scope} message={scope} />)}</div>
            )
        },
        {
            header: "Actions",
            enableSorting: false,
            enableColumnFilter: false,
            accessorFn: row => row.id,
            cell: cell => (
                <div className="flex space-x-1">
                    <Button isLoading={false} size="sm" onClick={() => redirectToFullAudit(cell.getValue<string>())}>
                        View
                    </Button>
                </div>
            )
        }
    ];
}

export default function FullAudits({ account, fullAudits }: { account: Account; staffUser: StaffUser; fullAudits: FullAudit[] }) {
    const navigate = useNavigate();

    function redirectToFullAudit(fullAuditId: string) {
        navigate(FULL_AUDIT_DETAIL_PAGE.replace(":fullAuditId", fullAuditId));
    }

    const fullAuditColumns = getFullAuditColumns(account.id, redirectToFullAudit);

    return <Table<FullAudit> data={fullAudits} columns={fullAuditColumns} />;
}
