import { Account, App, StaffUser, StaffUserRole } from "@vaultinum/vaultinum-api";
import { AppCode, message, SectionTitle, Select } from "@vaultinum/vaultinum-sdk";

import { setAppSetting } from "../../../services/accountService";

export default function Timestamping({
    account,
    staffUser,
    appSettings
}: {
    account: Account;
    staffUser: StaffUser;
    appSettings?: Account.AppSettings.Timestamping;
}): JSX.Element {
    async function onPlanChange(value: App.Timestamping.Plan) {
        try {
            await setAppSetting(account, AppCode.TIMESTAMPING, "plan", value);
            void message.success(`Timestamping service plan updated to ${value}`);
        } catch (error) {
            void message.error(`Error while updating user plan: ${error}`);
        }
    }
    return (
        <>
            <SectionTitle title="Plan" />
            {staffUser.roles.includes(StaffUserRole.ADMIN) ? (
                <Select.Basic
                    className="w-80"
                    options={Object.entries(App.Timestamping.Plan).map(([key, value]) => ({ label: key, value }))}
                    onChange={onPlanChange}
                    value={appSettings?.plan}
                />
            ) : (
                appSettings?.plan
            )}
        </>
    );
}
