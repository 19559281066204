import { Account, AccountInvitation, AccountUser, InvitationStatus } from "@vaultinum/vaultinum-api";
import { Column, Spin, Tag, formatDateTime, getAccountUsers } from "@vaultinum/vaultinum-sdk";
import { useEffect, useState } from "react";
import { TableLayout } from "../../../components";
import { getAccountInvitationsByStatuses } from "../../../services/accountService";

const isAccountInvitation = (user: AccountUser | AccountInvitation): user is AccountInvitation => "status" in user;

const usersColumns: Column<AccountInvitation | AccountUser>[] = [
    {
        header: "Id",
        accessorKey: "id"
    },
    {
        header: "Name",
        accessorKey: "name",
        accessorFn: row => (isAccountInvitation(row) ? row.email : `${row.firstName} ${row.lastName}`),
        cell: cell => {
            const user = cell.row.original;
            if (isAccountInvitation(user)) {
                if (user.status === InvitationStatus.PENDING) {
                    return <i>(Pending invitation)</i>;
                } else {
                    return <i>(Invitation accepted, pending account creation)</i>;
                }
            }
            return cell.getValue<string>();
        }
    },
    {
        header: "Email",
        accessorKey: "email"
    },
    {
        header: "Rights",
        accessorKey: "rights",
        cell: cell => (
            <div className="flex gap-2">
                {cell.getValue<string[]>().map(tag => (
                    <Tag key={tag} message={tag} />
                ))}
            </div>
        )
    },
    {
        header: "Creation date",
        accessorFn: row => (isAccountInvitation(row) ? row.sentDate : row.creationDate),
        cell: cell => formatDateTime(cell.getValue<Date>()),
        defaultSort: "desc"
    }
];

export default function AccountUsers({ account }: { account: Account }) {
    const [accountUsers, setAccountUsers] = useState<AccountUser[]>();
    const [accountInvitations, setAccountInvitations] = useState<AccountInvitation[]>();
    useEffect(() => getAccountUsers(account, setAccountUsers), [account]);
    useEffect(
        () => getAccountInvitationsByStatuses(account.id, [InvitationStatus.PENDING, InvitationStatus.ACCEPTED_NOT_REGISTERED], setAccountInvitations),
        [account]
    );

    const usersWithInvitations = accountUsers && accountInvitations ? [...accountUsers, ...accountInvitations] : undefined;
    if (!usersWithInvitations) {
        return <Spin />;
    }
    return <TableLayout<AccountUser | AccountInvitation> title="Users" items={usersWithInvitations} columns={usersColumns} />;
}
