import { Account, DepositStore, Survey, UserProfile } from "@vaultinum/vaultinum-api";
import { CodeAnalysisReport } from "@vaultinum/vaultinum-kys-api";
import { addQueryParamsToUrl } from "@vaultinum/vaultinum-sdk";
import { kebabCase } from "lodash";

// PUBLIC PAGES
export const PUBLIC_ROOT_PAGE = `${process.env.PUBLIC_URL}`;
export const LOGIN_PAGE = `${PUBLIC_ROOT_PAGE}/login`;
export const REGISTER_PAGE = `${PUBLIC_ROOT_PAGE}/register`;

// PRIVATE PAGES
export const PRIVATE_ROOT_PAGE = "/app";
export const ABSOLUTE_PRIVATE_ROOT_PAGE = `${process.env.PUBLIC_URL}${PRIVATE_ROOT_PAGE}`;
export const STAFF_USERS_PAGE = `${ABSOLUTE_PRIVATE_ROOT_PAGE}/staff-users`;
export const USER_PROFILES_PAGE = `${ABSOLUTE_PRIVATE_ROOT_PAGE}/user-profiles`;
export const USER_PROFILE_PAGE = `${USER_PROFILES_PAGE}/:userProfileId`;
export const ACCOUNTS_PAGE = `${ABSOLUTE_PRIVATE_ROOT_PAGE}/accounts`;
export const DOMAINS_PAGE = `${ABSOLUTE_PRIVATE_ROOT_PAGE}/domains`;
export const EVENTS_PAGE = `${ABSOLUTE_PRIVATE_ROOT_PAGE}/events`;
export const DEPOSIT_PAGE = `${ABSOLUTE_PRIVATE_ROOT_PAGE}/deposit`;
export const ESCROW_PAGE = `${ABSOLUTE_PRIVATE_ROOT_PAGE}/escrow`;
export const KYS1_PAGE = "/kys1";
export const ABSOLUTE_KYS1_PAGE = `${ABSOLUTE_PRIVATE_ROOT_PAGE}/kys1`;
export const KYS1_SURVEY_PAGE = "/survey";
export const ABSOLUTE_KYS1_SURVEY_PAGE = `${ABSOLUTE_PRIVATE_ROOT_PAGE}${KYS1_PAGE}${KYS1_SURVEY_PAGE}`;
export const KYS2_PAGE = `${ABSOLUTE_PRIVATE_ROOT_PAGE}/kys2`;
export const KYS2_REPORT_PAGE = "/report";
export const ABSOLUTE_KYS2_REPORT_PAGE = `${KYS2_PAGE}${KYS2_REPORT_PAGE}`;
export const FULL_AUDIT_PAGE = `${ABSOLUTE_PRIVATE_ROOT_PAGE}/full-audit`;
export const FULL_AUDIT_DETAIL_PAGE = `${FULL_AUDIT_PAGE}/report/:fullAuditId`;
export const MAINTENANCE_PAGE = `${ABSOLUTE_PRIVATE_ROOT_PAGE}/maintenance`;
export const STAFF_USER_PROFILE_PAGE = `${ABSOLUTE_PRIVATE_ROOT_PAGE}/profile`;
export const ESCROW_DETAIL_PAGE = `${ABSOLUTE_PRIVATE_ROOT_PAGE}/escrow/:escrowId`;
export const DEPOSIT_DETAIL_PAGE = `${ABSOLUTE_PRIVATE_ROOT_PAGE}/deposit/:depositStoreId`;
export const ESCROW_DEPOSIT_DETAIL_PAGE = `${ABSOLUTE_PRIVATE_ROOT_PAGE}/escrow/:escrowId/deposit/:depositStoreId`;
export const PROMOTIONS_PAGE = `${ABSOLUTE_PRIVATE_ROOT_PAGE}/promotions`;
export const PROMOTION_DETAIL_PAGE = `${PROMOTIONS_PAGE}/:promotionId`;
export const EDIT_NDA_PAGE = "non-disclosure-agreement";

export namespace AccountTabPage {
    export enum Tab {
        account = "Account",
        dueDiligence = "Due Diligence",
        deposits = "Deposits",
        escrows = "Escrows",
        events = "Events",
        timestamping = "Timestamping"
    }
}

export enum UserProfileTab {
    userProfile = "User profile",
    notifications = "Notifications"
}

export namespace DepositTabPage {
    export enum Tab {
        deposits = "Deposits",
        depositRequests = "Deposit requests"
    }
}

export namespace FullAuditTabPage {
    export enum Tab {
        requests = "Requests",
        nonDisclosureAgreement = "Non-disclosure agreement",
        events = "Events"
    }
}

export const getUserProfileLink = (userProfile: UserProfile) => USER_PROFILE_PAGE.replace(":userProfileId", userProfile.id);
export const getAccountLink = (account: Account, tab?: string) => getAccountLinkById(account.id, tab);
export const getAccountLinkById = (id: string, tab?: string) => addQueryParamsToUrl(`${ACCOUNTS_PAGE}/${id}`, { tab: kebabCase(tab) });
export const getAccountsLinkByDomain = (domainId?: string) => addQueryParamsToUrl(ACCOUNTS_PAGE, { domainId });
export const getKYS1ReportLink = (surveyVersion: Survey.Version) => `${ABSOLUTE_KYS1_SURVEY_PAGE}/${surveyVersion.surveyKey}/${surveyVersion.version}`;
export const getKYS1SurveyLink = (surveyKey: string | undefined) => `${ABSOLUTE_KYS1_SURVEY_PAGE}/${surveyKey}`;
export const getKYS1SurveySectionLink = (surveyKey: string, surveyVersion: number, sectionId: string) =>
    `${getKYS1SurveyLink(surveyKey)}/${surveyVersion}/${sectionId}`;
export const getKYS2CodeAnalysisReportLink = (report: CodeAnalysisReport) => `${ABSOLUTE_KYS2_REPORT_PAGE}/${report.fullAuditId}/${report.id}`;
export const getEscrowLink = (escrowId: string) => ESCROW_DETAIL_PAGE.replace(":escrowId", escrowId);
export const getDepositLink = (depositStore: DepositStore) => DEPOSIT_DETAIL_PAGE.replace(":depositStoreId", depositStore.id);
