import { Account, Deposit, DepositStore, WhiteLabelDomain } from "@vaultinum/vaultinum-api";
import {
    Column,
    CrossIcon,
    TableStorageKey,
    Tabs,
    Tag,
    addQueryParamsToUrl,
    formatDateTime,
    message,
    openNotificationWithIcon,
    useUrlSearch
} from "@vaultinum/vaultinum-sdk";
import { groupBy, kebabCase, mapValues } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SearchBarValues } from "../../../components/SearchBar";
import { depositStatusToType } from "../../../helpers";
import { getDepositAccountAppSettings } from "../../../services/accountService";
import { getDomains } from "../../../services/domainService";
import { DepositTabPage, getAccountLinkById, getDepositLink } from "../../../services/routing.service";
import { getDepositStoresWithoutEscrowId, getDeposits, removeCancelledDepositRequests } from "../../../services/vaultService";
import { StandardModelListView } from "../components";
import { DepositTagAction, DepositTagColor } from "./DepositTagAction";
import { PaymentLink } from "./PaymentLink";

async function clearCancelledRequest(deposit: Deposit) {
    try {
        await removeCancelledDepositRequests(deposit);
    } catch (e) {
        openNotificationWithIcon({ type: "error", description: e.message });
    }
}

function getDepositsColumns(depositsIndex: Record<string, Deposit[]>): Column<DepositStore>[] {
    return [
        { header: "Last deposit date", accessorFn: row => formatDateTime(depositsIndex[row.id]?.[0].sealedDate) },
        {
            header: "Organisation",
            accessorKey: "accountId",
            accessorFn: row => depositsIndex[row.id]?.[0].owner.name,
            cell: cell => {
                return (
                    <Link className="text-accent hover:underline" to={getAccountLinkById(cell.row.original.accountId)}>
                        {depositsIndex[cell.row.original.id]?.[0].owner.name}
                    </Link>
                );
            }
        },
        { header: "IDDN", accessorFn: row => depositsIndex[row.id]?.[0].iddn },
        {
            header: "Payment",
            accessorFn: row => row.paymentMethod,
            cell: cell => {
                const { subscriptionId, paymentMethod } = cell.row.original;
                if (subscriptionId || paymentMethod === Deposit.PaymentMethod.OFFLINE) {
                    return <PaymentLink isPaidOffline={paymentMethod === Deposit.PaymentMethod.OFFLINE} subscriptionId={subscriptionId} />;
                }
                return <DepositTagAction color={DepositTagColor.ORANGE} children="PENDING" />;
            },
            size: 130
        },
        {
            header: "Plan",
            accessorFn: row =>
                depositsIndex[row.id].map(
                    deposit =>
                        (
                            deposit as Deposit & {
                                accountAppSettings: Account.AppSettings;
                            }
                        ).accountAppSettings?.plan
                ),
            cell: cell => {
                return <Tag message={cell.getValue<string>()[0]} />;
            }
        },
        {
            header: "Status",
            accessorKey: "deposit",
            accessorFn: row => depositsIndex[row.id]?.[0],
            cell: cell => {
                const deposit = cell.getValue<Deposit>();
                const isPendingRequest =
                    deposit.deleteRequest?.status === Deposit.ActionRequestStatus.PENDING ||
                    deposit.releaseRequest?.status === Deposit.ActionRequestStatus.PENDING;
                return (
                    <div className="flex flex-col gap-2">
                        <Tag type={depositStatusToType(deposit.status)} message={deposit.status} />
                        {!isPendingRequest && (deposit.deleteRequest?.status ?? deposit.releaseRequest?.status) === Deposit.ActionRequestStatus.CANCELLED && (
                            <Tag icon={CrossIcon} type="info" message="REQUEST CANCELLED" onClick={() => clearCancelledRequest(deposit)} />
                        )}
                        {deposit.releaseRequest?.status === Deposit.ActionRequestStatus.PENDING && <Tag type="warning" message="RELEASE REQUESTED" />}
                        {deposit.deleteRequest?.status === Deposit.ActionRequestStatus.PENDING && <Tag type="warning" message="DELETE REQUESTED" />}
                    </div>
                );
            }
        },

        { header: "Bundle Id", accessorFn: row => depositsIndex[row.id]?.[0].bundleId },
        { header: "email", accessorFn: row => depositsIndex[row.id]?.[0].depositary.email, hide: true }
    ];
}

function renderInitiator(depositStore: DepositStore, domains: WhiteLabelDomain[]) {
    // Waiting for name to be available
    return domains.find(domain => domain.id === depositStore.whiteLabelDomainId)?.fqdn;
}

function getDepositRequestsColumns(depositStoresWithRequestIndex: Record<string, DepositStore[]>, domains: WhiteLabelDomain[]): Column<DepositStore>[] {
    return [
        {
            header: "Initiator",
            accessorFn: row => row,
            enableColumnFilter: true,
            enableSorting: true,
            cell: cell => renderInitiator(cell.getValue<DepositStore>(), domains)
        },
        { header: "Owner", accessorFn: row => depositStoresWithRequestIndex[row.id]?.[0].depositRequest?.owner.name },
        { header: "Name", accessorFn: row => depositStoresWithRequestIndex[row.id]?.[0].depositRequest?.name },
        { header: "Description", accessorFn: row => depositStoresWithRequestIndex[row.id]?.[0].depositRequest?.description }
    ];
}

export default function DepositView({ ignoredAccountIds }: { ignoredAccountIds?: string[] }): JSX.Element {
    const [deposits, setDeposits] = useState<Deposit[]>([]);
    const [depositStores, setDepositStores] = useState<DepositStore[]>([]);
    const [domains, setDomains] = useState<WhiteLabelDomain[]>([]);
    const [depositsIndex, setDepositsIndex] = useState<Record<string, Deposit[]>>({});

    useEffect(() => {
        getDomains(setDomains);
    }, []);

    const depositStoresWithRequest = useMemo(
        () => depositStores.filter(store => !store.escrowId && store.depositRequest?.status === Deposit.Status.OPEN),
        [depositStores]
    );

    useEffect(() => {
        getDepositStoresWithoutEscrowId(setDepositStores);
    }, []);

    useEffect(() => {
        void (async () => {
            try {
                const deposits = await getDeposits();
                setDeposits(deposits);
            } catch (e) {
                void message.error("Failed to load deposits", e);
            }
        })();
    }, []);

    const depositsWithPlan = useMemo(async () => {
        try {
            return await Promise.all(
                deposits.map(async deposit => {
                    const depositStore = depositStores.find(store => store.id === deposit.depositStoreId);
                    if (!depositStore) {
                        return deposit;
                    }
                    const accountAppSettings = await getDepositAccountAppSettings(depositStore.accountId);
                    return { ...deposit, accountAppSettings };
                })
            );
        } catch (e) {
            await message.error("Failed to load app settings", e);
            return [];
        }
    }, [depositStores, deposits]);

    useEffect(() => {
        void (async () => {
            const index = mapValues(
                groupBy(await depositsWithPlan, deposit => deposit.depositStoreId),
                deposits => deposits.sort((a, b) => b.createdDate.getTime() - a.createdDate.getTime())
            );
            setDepositsIndex(index);
        })();
    }, [depositsWithPlan]);

    const depositStoresWithRequestIndex = useMemo(
        () =>
            mapValues(
                groupBy(depositStoresWithRequest, store => store.id),
                depositStoresWithRequest => depositStoresWithRequest.sort((a, b) => b.creationDate.getTime() - a.creationDate.getTime())
            ),
        [depositStoresWithRequest]
    );

    const applySearchOptionsForDeposits = (depositStore: DepositStore, { hideIgnored }: SearchBarValues) => {
        const relatedDeposits = depositsIndex[depositStore.id];
        if (!relatedDeposits || !depositStore.creationDate) {
            return false;
        }
        if (hideIgnored && ignoredAccountIds?.includes(depositStore.accountId)) {
            return false;
        }
        return true;
    };

    const applySearchOptionsForDepositRequests = (depositStore: DepositStore, { hideIgnored }: SearchBarValues) => {
        const relatedDeposits = depositStoresWithRequestIndex[depositStore.id];
        if (!relatedDeposits || !depositStore.creationDate) {
            return false;
        }
        if (hideIgnored && ignoredAccountIds?.includes(depositStore.accountId)) {
            return false;
        }
        return true;
    };
    const searchUrlParams = useUrlSearch() as { tab?: string };
    const navigate = useNavigate();
    const depositsColumns = useMemo(() => getDepositsColumns(depositsIndex), [depositsIndex]);
    const depositRequestsColumns = useMemo(() => getDepositRequestsColumns(depositStoresWithRequestIndex, domains), [depositStoresWithRequestIndex, domains]);

    const activeTab = searchUrlParams.tab || kebabCase(DepositTabPage.Tab.deposits);

    function onTabChange(newTab: string) {
        navigate(addQueryParamsToUrl(location.pathname, { ...searchUrlParams, tab: kebabCase(newTab) }));
    }

    return (
        <Tabs activeKey={activeTab} onChange={onTabChange} className="h-full flex-1">
            <Tabs.TabPane className="icon space-y-2" tab={DepositTabPage.Tab.deposits} key={kebabCase(DepositTabPage.Tab.deposits)}>
                <StandardModelListView<DepositStore>
                    name={DepositTabPage.Tab.deposits}
                    getModelItems={getDepositStoresWithoutEscrowId}
                    applySearchOptions={applySearchOptionsForDeposits}
                    modelPageLink={getDepositLink}
                    columns={depositsColumns}
                    storageKey={TableStorageKey.DEPOSIT}
                />
            </Tabs.TabPane>
            <Tabs.TabPane className="icon space-y-2" tab={DepositTabPage.Tab.depositRequests} key={kebabCase(DepositTabPage.Tab.depositRequests)}>
                <StandardModelListView<DepositStore>
                    name={DepositTabPage.Tab.depositRequests}
                    getModelItems={getDepositStoresWithoutEscrowId}
                    applySearchOptions={applySearchOptionsForDepositRequests}
                    columns={depositRequestsColumns}
                />
            </Tabs.TabPane>
        </Tabs>
    );
}
