import { Account, Deposit } from "@vaultinum/vaultinum-api";
import { VerifiedDepositReport } from "@vaultinum/vaultinum-deposit-api";
import {
    Alert,
    BaseLang,
    Button,
    Buttons,
    CellContext,
    Column,
    DownloadIcon,
    Input,
    Modal,
    ModalButton,
    Select,
    Table,
    Tag,
    ToggleButton,
    UnlockIcon,
    formatDateTime,
    openNotificationWithIcon,
    useLang
} from "@vaultinum/vaultinum-sdk";
import classNames from "classnames";
import fileSize from "filesize";
import { orderBy } from "lodash";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { getCountryName } from "../../../common/AccountTools";
import { handleAsyncActionWithMessages } from "../../../common/AsyncTools";
import { isAccountValidForRightsTransfer } from "../../../common/VaultTools";
import { AsyncActionButton, UploadDepositReportModal } from "../../../components";
import { depositStatusToType } from "../../../helpers";
import { getAllAccounts } from "../../../services/accountService";
import { downloadVerifiedDepositPDF } from "../../../services/reportService";
import { downloadCertificate, getVerifiedDepositReport, sealVerifiedDeposit, transferDeposits } from "../../../services/vaultService";
import { CertificateDownloadButton } from "../components";

const depositsColumn: Column<Deposit>[] = [
    {
        header: "Creation date",
        accessorKey: "createdDate",
        cell: cell => formatDateTime(cell.getValue<Date>()),
        size: 160
    },
    {
        header: "Status",
        accessorKey: "status",
        cell: cell => {
            const status = cell.getValue<Deposit.Status>();
            return <Tag type={depositStatusToType(status)} message={status} />;
        },
        size: 90
    },
    {
        header: "Iddn",
        accessorKey: "iddn"
    },
    {
        header: "Bundle Id",
        accessorKey: "bundleId"
    },
    {
        header: "Name",
        accessorKey: "name"
    },
    {
        header: "Description",
        accessorKey: "description"
    },
    {
        header: "Size",
        accessorKey: "size",
        cell: cell => fileSize(cell.getValue<number>() || 0)
    },
    {
        header: "Version",
        accessorKey: "version",
        size: 95
    },
    {
        header: "Sealed Date",
        accessorKey: "sealedDate",
        cell: cell => formatDateTime(cell.getValue<Date>()),
        size: 160
    },
    {
        header: "Actions",
        enableSorting: false,
        enableColumnFilter: false,
        cell: cell => <DepositActions deposit={cell.row.original} />,
        size: 250
    }
];

function renderTransferCheckbox(deposit: Deposit, selectedDeposits: Deposit[], toggleDepositSelection: (deposit: Deposit) => void) {
    return (
        <div className="flex items-center justify-center">
            <Input.Checkbox
                checked={!!selectedDeposits.find(selectedDeposit => selectedDeposit.iddn === deposit.iddn)}
                onChange={() => toggleDepositSelection(deposit)}
            />
        </div>
    );
}

function InformationEntry({ label, value }: { label: string; value?: string }): JSX.Element {
    return (
        <>
            <div className={classNames("font-normal", { "text-danger": !value, "text-primary": !!value })}>{label}</div>
            {value ? <span>{value}</span> : <span className="text-danger">-</span>}
        </>
    );
}

function DepositActions({ deposit }: { deposit: Deposit }): JSX.Element | null {
    const lang = useLang<BaseLang>();
    const [verifiedDepositReport, setVerifiedDepositReport] = useState<VerifiedDepositReport | null>();
    const [loading, setLoading] = useState(false);
    useEffect(() => getVerifiedDepositReport(deposit, setVerifiedDepositReport), [deposit]);

    async function downloadVerifiedReport(report: VerifiedDepositReport) {
        setLoading(true);
        try {
            await downloadVerifiedDepositPDF(report);
        } catch {
            openNotificationWithIcon({ type: "error", description: "An error occured while downloading the report" });
        } finally {
            setLoading(false);
        }
    }

    const onConfirm = async () => {
        setLoading(true);
        try {
            await handleAsyncActionWithMessages(() =>
                sealVerifiedDeposit(deposit)
                    .then(() => "Deposit successfully sealed")
                    .catch(() => {
                        throw new Error("An error occured while sealing the deposit");
                    })
            );
        } catch {
            openNotificationWithIcon({ type: "error", description: "An error occured while sealing the deposit" });
        } finally {
            setLoading(false);
        }
    };

    if (verifiedDepositReport && deposit.status === Deposit.Status.SEALED) {
        return (
            <Buttons.Dropdown
                icon={DownloadIcon}
                onClick={() => downloadVerifiedReport(verifiedDepositReport)}
                actions={[
                    {
                        label: "Verified report",
                        onClick: () => downloadVerifiedReport(verifiedDepositReport)
                    },
                    {
                        label: "Certificate",
                        onClick: () => downloadCertificate(deposit.depositStoreId, deposit.iddn)
                    }
                ]}
                data-id="btn-download-certificate"
                isLoading={loading}
                children="Download"
            />
        );
    }

    const components: JSX.Element[] = [];
    if (verifiedDepositReport) {
        components.push(
            <AsyncActionButton
                icon={DownloadIcon}
                onClick={() => downloadVerifiedReport(verifiedDepositReport).then(() => "Report successfully downloaded")}
                children="Download report"
            />
        );
    } else if ([Deposit.Status.VERIFICATION_PENDING, Deposit.Status.CONTROL_PENDING].includes(deposit.status)) {
        components.push(<UploadDepositReportModal deposit={deposit} />);
    }
    if (verifiedDepositReport && ![Deposit.Status.SEALED, Deposit.Status.REVOKED].includes(deposit.status)) {
        components.push(
            <ModalButton
                icon={UnlockIcon}
                title="Seal"
                children={
                    <div className="space-y-2">
                        <h4>Do you want to definitively seal this deposit?</h4>
                        <Alert.Warning message="After confirmation, you won't be able to revert this action." />
                    </div>
                }
                onConfirm={onConfirm}
                buttonProps={{
                    children: "Seal",
                    isLoading: false,
                    icon: UnlockIcon,
                    isDisabled: loading
                }}
                lang={lang}
            />
        );
    }
    if (deposit.status === Deposit.Status.SEALED) {
        components.push(<CertificateDownloadButton deposit={deposit} />);
    }
    return <div className="flex gap-2">{components}</div>;
}

function AccountInformation({ account }: { account: Account }): JSX.Element {
    return (
        <div className="grid grid-cols-3 bg-gray-100 p-2">
            <InformationEntry label={"Account Id"} value={account.id} />
            <InformationEntry label={"Registration Number"} value={account.companyRegistrationNumber} />
            <InformationEntry label={"Company Nationality"} value={account.companyNationality ? getCountryName(account.companyNationality) : undefined} />
            <InformationEntry label={"Address"} value={account.companyAddress?.line1} />
            <InformationEntry label={"City"} value={account.companyAddress?.city} />
            <InformationEntry label={"Country"} value={account.companyAddress?.country ? getCountryName(account.companyAddress?.country) : undefined} />
        </div>
    );
}

function DepositLine({ deposit }: { deposit: Deposit }): JSX.Element {
    return (
        <div key={deposit.iddn} className="flex space-x-2">
            <span className="text-primary">{deposit.iddn}</span>
            <span>{deposit.name}</span>
        </div>
    );
}

function DepositTransferInformation({
    deposits,
    accounts,
    onAccountSelected,
    targetAccount
}: {
    deposits: Deposit[];
    accounts: Account[];
    onAccountSelected: (accountId: string) => void;
    targetAccount?: Account;
}): JSX.Element {
    return (
        <>
            <div className="mb-4 flex items-center">
                <div className="w-36 font-semibold">Target Account</div>
                <Select.Search
                    className="w-full"
                    placeholder="Select account"
                    value={targetAccount?.id}
                    onChange={onAccountSelected}
                    options={accounts.map(account => ({ value: account.id, label: account.companyName }))}
                />
            </div>
            {targetAccount && (
                <>
                    {!isAccountValidForRightsTransfer(targetAccount) && (
                        <Alert.Danger message="Rights transfer can not be performed due to missing information" />
                    )}
                    <AccountInformation account={targetAccount} />
                </>
            )}
            <div className="my-2 font-semibold">Selected Deposits</div>
            <div className="bg-gray-100 p-2">
                {deposits.map(deposit => (
                    <DepositLine key={deposit.iddn} deposit={deposit} />
                ))}
            </div>
        </>
    );
}

function RightsTransferModal({
    accounts,
    deposits,
    transferEnabled,
    setTransferEnabled,
    onTransferCompleted
}: {
    accounts: Account[];
    deposits: Deposit[];
    transferEnabled: boolean;
    setTransferEnabled: Dispatch<SetStateAction<boolean>>;
    onTransferCompleted: () => void;
}) {
    const lang = useLang<BaseLang>();
    const [targetAccount, setTargetAccount] = useState<Account>();
    const [visibleModal, setVisibleModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const sourceAccountId = deposits.length ? deposits[0].owner.accountId : null;

    function onStartTransfer() {
        setVisibleModal(true);
    }

    function onClose() {
        setTargetAccount(undefined);
        setVisibleModal(false);
    }

    async function doRightsTransfer() {
        try {
            setLoading(true);
            if (targetAccount) {
                await transferDeposits(
                    deposits.map(deposit => deposit.iddn),
                    targetAccount?.id
                );
            }
            onClose();
            onTransferCompleted();
        } catch (exception) {
            openNotificationWithIcon({ type: "error", description: "Failed to transfer rights" });
        } finally {
            setLoading(false);
        }
    }

    function onAccountSelected(accountId: string) {
        setTargetAccount(accounts.find(account => account.id === accountId));
    }

    function toggleTransferEnabled() {
        setTransferEnabled(prev => !prev);
    }

    const sortedAccounts = orderBy(
        accounts.filter(account => account.id !== sourceAccountId).filter(account => !!account.companyName),
        [account => account.companyName.toLowerCase()],
        ["asc"]
    );

    return (
        <>
            <div className="flex space-x-2">
                {transferEnabled && <Button isLoading={false} onClick={onStartTransfer} children="Start Rights Transfer" />}
                <ToggleButton text="Perform Rights Transfer" isChecked={transferEnabled} onClick={toggleTransferEnabled} />
            </div>
            <Modal
                size="md"
                isOpen={visibleModal}
                title="Deposits rights transfer"
                okText="Confirm"
                onClose={onClose}
                isLoading={loading}
                isDisabled={!isAccountValidForRightsTransfer(targetAccount) || !deposits.length}
                onConfirm={doRightsTransfer}
                lang={lang}
            >
                <Alert.Warning message="Beware that this operation is irreversible. Once you have transferred the rights of the selected deposits to another account you can not revert. " />
                {!deposits.length && <Alert.Danger message="No deposit has been selected for rights transfer." />}
                {!!deposits.length && (
                    <DepositTransferInformation
                        deposits={deposits}
                        accounts={sortedAccounts}
                        targetAccount={targetAccount}
                        onAccountSelected={onAccountSelected}
                    />
                )}
            </Modal>
        </>
    );
}

export default function Deposits({ deposits, isEscrowContext }: { deposits: Deposit[]; isEscrowContext: boolean }): JSX.Element {
    const [accounts, setAccounts] = useState<Account[]>([]);
    const [selectedDeposits, setSelectedDeposits] = useState<Deposit[]>([]);
    const [isTransferEnabled, setTransferEnabled] = useState<boolean>(false);
    useEffect(() => getAllAccounts(setAccounts), []);

    function toggleDepositSelection(deposit: Deposit) {
        if (selectedDeposits.find(selectedDeposit => selectedDeposit.iddn === deposit.iddn)) {
            setSelectedDeposits(prev => prev.filter(selectedDeposit => selectedDeposit.iddn !== deposit.iddn));
        } else {
            setSelectedDeposits(prev => [...prev, deposit]);
        }
    }

    function onTransferCompleted(): void {
        setSelectedDeposits([]);
    }

    const transferColumn = {
        header: "Select for transfer",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cell: CellContext<Deposit, unknown>) => renderTransferCheckbox(cell.row.original, selectedDeposits, toggleDepositSelection)
    };

    return (
        <>
            {!isEscrowContext && (
                <div className="flex justify-end">
                    <RightsTransferModal
                        accounts={accounts}
                        deposits={selectedDeposits}
                        transferEnabled={isTransferEnabled}
                        setTransferEnabled={setTransferEnabled}
                        onTransferCompleted={onTransferCompleted}
                    />
                </div>
            )}
            <div className="w-full overflow-hidden">
                <Table<Deposit> data={deposits} columns={[...depositsColumn, ...(isTransferEnabled ? [transferColumn] : [])]} />
            </div>
        </>
    );
}
