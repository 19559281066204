import { Promotion, WhiteLabelDomain } from "@vaultinum/vaultinum-api";
import { AppCode, Tag, Tooltip, formatDate, formatDateFromNow } from "@vaultinum/vaultinum-sdk";
import dayjs from "dayjs";

const today = new Date();

export function formatDates(promotion: Promotion): JSX.Element {
    const dates = `${formatDate(promotion.startDate)} → ${formatDate(promotion.endDate)}`;
    const diffWithStartDate = dayjs(promotion.startDate).diff(dayjs(today).startOf("day").toDate(), "day");
    const diffWithEndDate = dayjs(promotion.endDate).diff(dayjs(today).endOf("day").toDate(), "day");
    if (diffWithStartDate > 0) {
        return (
            <Tooltip title={dates}>
                <div className="flex gap-2 items-baseline">
                    <Tag type="info" message="Scheduled" />
                    <span className="font-light italic text-grey-primary text-xs">Starts {formatDateFromNow(promotion.startDate)}</span>
                </div>
            </Tooltip>
        );
    }
    if (diffWithEndDate >= 0) {
        return (
            <Tooltip title={dates}>
                <div className="flex gap-2 items-baseline">
                    <Tag type="success" message="Ongoing" />
                    <span className="font-light italic text-grey-primary text-xs">Ends {formatDateFromNow(promotion.endDate)}</span>
                </div>
            </Tooltip>
        );
    }
    return (
        <Tooltip title={dates}>
            <Tag type="default" message="Over" />
        </Tooltip>
    );
}

export function formatOffer(offer: Promotion["offer"]): string {
    return Object.entries(offer)
        .map(([appCode, options]) => {
            switch (appCode) {
                case AppCode.FULL_AUDIT:
                    return `${appCode} (${options?.scopes.join(", ")})`;
                default:
                    return appCode;
            }
        })
        .join(", ");
}

export function formatJoinLink(promotion: Promotion, domain: WhiteLabelDomain | null | undefined): string {
    const host = domain?.fqdn ? `https://${domain.fqdn}` : process.env.REACT_APP_VAULTINUM_HOME;
    return `${host}/api/promotion/join/${promotion.id}?email={{contact.email}}&companyName={{contact.company}}`;
}
