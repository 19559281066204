import { SupportedLanguageCode, Survey, SurveyRecord } from "@vaultinum/vaultinum-api";
import {
    BaseLang,
    Button,
    Buttons,
    Column,
    CopyIcon,
    DeleteIcon,
    DownloadIcon,
    InfoCircleIcon,
    Modal,
    ModalButton,
    ModalHelper,
    StarTwoToneIcon,
    Table,
    Tag,
    User,
    formatDateTime,
    getSurveyLangs,
    getSurveyRecordsBySurveyKey,
    message,
    useLang,
    useModal
} from "@vaultinum/vaultinum-sdk";
import { uniq } from "lodash";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Flag, JsonEditor, StaffUserName } from "../../../components";
import { getKYS1ReportLink } from "../../../services/routing.service";
import { deleteSurveyVersion, getSurveyVersions, newDraftSurveyVersion, setSurveyAsLatest } from "../../../services/surveyService";
import CloneSurveyModal from "./CloneSurveyModal";
import UploadSurveyModal from "./UploadSurveyModal";

function getColumns(
    isAdmin: boolean,
    goToEditSurvey: (surveyVersion: Survey.Version) => void,
    onSurveyLatest: (surveyVersion: Survey.Version) => void,
    onCloneSurvey: (surveyVersion: Survey.Version) => void,
    onSurveyVersionDelete: (surveyVersion: Survey.Version) => void,
    onSurveyExport: (surveyVersion: Survey.Version) => void
): Column<Survey.Version>[] {
    return [
        {
            header: "Last updated",
            accessorKey: "updatedDate",
            cell: cell => formatDateTime(cell.getValue<Date>())
        },
        {
            header: "Last updated By",
            accessorKey: "updatedByUID",
            cell: cell => <StaffUserName uid={cell.getValue<string>()} />
        },
        {
            header: "Published languages",
            accessorFn: row => row.publishedLangs.sort(),
            cell: cell => (
                <div className="flex gap-1">
                    {cell.getValue<SupportedLanguageCode[]>().map(lang => (
                        <Flag key={lang} countryCode={lang} />
                    ))}
                </div>
            )
        },
        {
            header: "Version",
            accessorKey: "version",
            defaultSort: "desc"
        },
        {
            header: "Latest",
            accessorKey: "latest",
            cell: cell => {
                const isLatest = cell.getValue<boolean>();
                return isLatest ? <Tag type="info" icon={StarTwoToneIcon} /> : null;
            }
        },
        {
            header: "Action",
            enableSorting: false,
            enableColumnFilter: false,
            size: 240,
            cell: cell => {
                const surveyVersion = cell.row.original;
                return (
                    <div className="flex gap-2">
                        <Button isLoading={false} onClick={() => goToEditSurvey(surveyVersion)} children="View" />
                        {isAdmin && (
                            <>
                                <Buttons.Icon
                                    type="default"
                                    icon={StarTwoToneIcon}
                                    onClick={() => onSurveyLatest(surveyVersion)}
                                    isDisabled={surveyVersion.latest || !surveyVersion.publishedLangs.length}
                                    isLoading={false}
                                    title="Set as latest"
                                />
                                <Buttons.Icon
                                    title="Copy version"
                                    type="default"
                                    icon={CopyIcon}
                                    onClick={() => onCloneSurvey(surveyVersion)}
                                    isLoading={false}
                                />
                                <Buttons.Icon
                                    type="default"
                                    icon={DeleteIcon}
                                    onClick={() => onSurveyVersionDelete(surveyVersion)}
                                    isDisabled={surveyVersion.latest}
                                    isLoading={false}
                                    title="Delete version"
                                />
                                <Buttons.Icon
                                    type="default"
                                    icon={DownloadIcon}
                                    onClick={() => onSurveyExport(surveyVersion)}
                                    isLoading={false}
                                    title="Download version"
                                />
                            </>
                        )}
                    </div>
                );
            }
        }
    ];
}

export function SurveyVersionsView({ isAdmin, user, survey }: { isAdmin: boolean; user: User; survey: Survey }) {
    const lang = useLang<BaseLang>();
    const navigate = useNavigate();
    const { isOpen, doOpen, doClose } = useModal();

    const [surveyVersions, setSurveyVersions] = useState<Survey.Version[]>([]);
    const [records, setRecords] = useState<SurveyRecord[]>([]);
    const [showUploadSurvey, setShowUploadSurvey] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedSurveyToClone, setSelectedSurveyToClone] = useState<Survey.Version | null>();
    const [surveyValue, setSurveyValue] = useState("");

    useEffect(() => getSurveyVersions(survey.key, setSurveyVersions), [survey.key]);
    useEffect(() => getSurveyRecordsBySurveyKey(survey.key, setRecords), [survey.key]);

    const goToEditSurvey = (surveyVersion: Survey.Version) => navigate(getKYS1ReportLink(surveyVersion));

    const startNewVersion = async () => {
        setIsLoading(true);
        try {
            await newDraftSurveyVersion(survey.key);
        } catch (e) {
            if (e instanceof Error) {
                void message.error(e.message);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const onSurveyVersionDelete = (surveyVersion: Survey.Version) => {
        let children = "";
        if (surveyVersion.publishedLangs.length) {
            const matchingRecords = records.filter(record => record.surveyVersion === surveyVersion.version);
            const fullAuditCount = uniq(matchingRecords.map(record => record.fullAuditId)).length;
            if (fullAuditCount === 0) {
                children = `This revision is used by ${matchingRecords.length} survey records and ${fullAuditCount} fullAudits. This revision can be safely removed.`;
            } else {
                children = `This revision is used by ${matchingRecords.length} survey records and ${fullAuditCount} fullAudits. These clients will not longer be able to interact with these filled in surveys.`;
            }
        } else {
            children = "This revision is not published and can be safely removed.";
        }
        ModalHelper.Confirm({
            title: "Delete survey version",
            children: `Do you want to delete this survey revision? ${children}`,
            onConfirm: () => deleteSurveyVersion(surveyVersion, true).catch(e => message.error(e.message)),
            lang,
            icon: InfoCircleIcon
        });
    };

    const onSurveyExport = async (surveyVersion: Survey.Version) => {
        const langs = await getSurveyLangs(surveyVersion.surveyKey, surveyVersion.version);
        setSurveyValue(JSON.stringify({ surveyVersion, langs }, null, 4));
        doOpen();
    };

    const onSurveyLatest = (surveyVersion: Survey.Version) => {
        return ModalHelper.Confirm({
            title: "Set survey as latest",
            children: "This survey will become the default survey new clients will use and the target update version for existing clients.",
            onConfirm: () => setSurveyAsLatest(survey.key, surveyVersion.version),
            lang
        });
    };

    const closeUploadSurveyModal = () => {
        setShowUploadSurvey(false);
    };

    const closeCloneSurveyModal = () => {
        setSelectedSurveyToClone(null);
    };

    const onCloneSurvey = (surveyVersion: Survey.Version) => {
        setSelectedSurveyToClone(surveyVersion);
    };

    const columns = getColumns(isAdmin, goToEditSurvey, onSurveyLatest, onCloneSurvey, onSurveyVersionDelete, onSurveyExport);

    return (
        <div className="flex h-full w-full flex-col gap-2">
            {showUploadSurvey && survey && <UploadSurveyModal isOpen={showUploadSurvey} onClose={closeUploadSurveyModal} refSurvey={survey} user={user} />}
            {selectedSurveyToClone && <CloneSurveyModal isOpen onClose={closeCloneSurveyModal} surveyVersion={selectedSurveyToClone} />}
            <div className="flex gap-1">
                <ModalButton.Confirm
                    buttonProps={{ children: "New version", isLoading }}
                    title="New survey version"
                    children="This will create an unpublished copy of the latest version"
                    onConfirm={startNewVersion}
                    lang={lang}
                />
                <Button children="Upload new survey" type="default" onClick={() => setShowUploadSurvey(true)} isLoading={false} />
            </div>
            <div className="h-full overflow-hidden">
                <Table<Survey.Version> data={surveyVersions} columns={columns} />
            </div>
            <Modal
                size="lg"
                onClose={doClose}
                isOpen={isOpen}
                title="Export survey"
                lang={lang}
                children={<JsonEditor value={surveyValue} schema={{ type: "object" }} isDisabled isLight />}
                onConfirm={async () => {
                    await navigator.clipboard.writeText(surveyValue);
                    void message.success("Copied to clipboard");
                }}
                okText="Copy Survey"
                maxHeight="90vh"
            />
        </div>
    );
}
