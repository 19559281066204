import { StaffUser, StaffUserRole } from "@vaultinum/vaultinum-api";
import { User } from "@vaultinum/vaultinum-sdk";
import { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { AppName } from "./helpers";
import { ProtectedRoute } from "./ProtectedRoute";
import { getIgnoredAccountIds } from "./services/accountService";
import {
    ABSOLUTE_KYS1_PAGE,
    ABSOLUTE_PRIVATE_ROOT_PAGE,
    ACCOUNTS_PAGE,
    DEPOSIT_DETAIL_PAGE,
    DEPOSIT_PAGE,
    DOMAINS_PAGE,
    ESCROW_DEPOSIT_DETAIL_PAGE,
    ESCROW_DETAIL_PAGE,
    ESCROW_PAGE,
    EVENTS_PAGE,
    FULL_AUDIT_PAGE,
    KYS2_PAGE,
    LOGIN_PAGE,
    MAINTENANCE_PAGE,
    PRIVATE_ROOT_PAGE,
    PROMOTIONS_PAGE,
    PROMOTION_DETAIL_PAGE,
    PUBLIC_ROOT_PAGE,
    REGISTER_PAGE,
    STAFF_USERS_PAGE,
    STAFF_USER_PROFILE_PAGE,
    USER_PROFILES_PAGE
} from "./services/routing.service";
import {
    AccountsPage,
    DepositPage,
    DepositView,
    DomainsPage,
    EscrowPage,
    EscrowsView,
    EventsPage,
    HomePage,
    Kys1Page,
    Kys2Page,
    MaintenancePage,
    ProfilePage,
    StaffUsersPage,
    UserProfilesPage
} from "./views/private";
import FullAuditRouter from "./views/private/full-audit/FullAuditRouter";
import PromotionPage from "./views/private/promotion/PromotionPage";
import PromotionsPage from "./views/private/promotion/PromotionsPage";
import { LandingPage, LoginPage, RegisterPage, UnauthorizedPage } from "./views/public";

const PrivateRoutes = ({ user, roles, staffUser }: { user: User; roles: StaffUserRole[]; staffUser: StaffUser | null }) => {
    const [ignoredAccountIds, setIgnoredAccountIds] = useState<string[]>([]);

    useEffect(() => getIgnoredAccountIds(setIgnoredAccountIds), []);
    const wasAdminWhenLogged = roles.includes(StaffUserRole.ADMIN);
    if (roles.length === 0 || !staffUser) {
        return <UnauthorizedPage />;
    }
    return (
        <div className="flex gap-2 h-full max-h-full flex-col overflow-auto bg-slate-50 px-8 py-4">
            <Routes>
                <Route path={PRIVATE_ROOT_PAGE} element={<HomePage roles={roles} staffUser={staffUser} />} />
                <Route path={STAFF_USERS_PAGE} element={<StaffUsersPage wasAdminWhenLogged={wasAdminWhenLogged} />} />
                <Route
                    path={`${USER_PROFILES_PAGE}/*`}
                    element={
                        <ProtectedRoute staffUser={staffUser} requiredRoles={[StaffUserRole.CLIENT_SERVICE]}>
                            <UserProfilesPage />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={`${ACCOUNTS_PAGE}/*`}
                    element={
                        <ProtectedRoute staffUser={staffUser} requiredRoles={[StaffUserRole.ADMIN, StaffUserRole.CLIENT_SERVICE]}>
                            <AccountsPage staffUser={staffUser} />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={`${DOMAINS_PAGE}/*`}
                    element={
                        <ProtectedRoute staffUser={staffUser} requiredRoles={[StaffUserRole.ADMIN]}>
                            <DomainsPage staffUser={staffUser} />
                        </ProtectedRoute>
                    }
                />
                {process.env.REACT_APP_BRAND_NAME !== AppName.EVIDENCY && (
                    <>
                        <Route
                            path={`${EVENTS_PAGE}/*`}
                            element={
                                <ProtectedRoute staffUser={staffUser} requiredRoles={[StaffUserRole.CLIENT_SERVICE]}>
                                    <EventsPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={`${PROMOTIONS_PAGE}/*`}
                            element={
                                <ProtectedRoute staffUser={staffUser} requiredRoles={[StaffUserRole.ADMIN]}>
                                    <PromotionsPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={PROMOTION_DETAIL_PAGE}
                            element={
                                <ProtectedRoute staffUser={staffUser} requiredRoles={[StaffUserRole.ADMIN]}>
                                    <PromotionPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={DEPOSIT_PAGE}
                            element={
                                <ProtectedRoute staffUser={staffUser} requiredRoles={[StaffUserRole.CLIENT_SERVICE]}>
                                    <DepositView ignoredAccountIds={ignoredAccountIds} />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={`${DEPOSIT_DETAIL_PAGE}/*`}
                            element={
                                <ProtectedRoute staffUser={staffUser} requiredRoles={[StaffUserRole.CLIENT_SERVICE]}>
                                    <DepositPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={`${ESCROW_DEPOSIT_DETAIL_PAGE}/*`}
                            element={
                                <ProtectedRoute staffUser={staffUser} requiredRoles={[StaffUserRole.CLIENT_SERVICE]}>
                                    <DepositPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={ESCROW_PAGE}
                            element={
                                <ProtectedRoute staffUser={staffUser} requiredRoles={[StaffUserRole.CLIENT_SERVICE]}>
                                    <EscrowsView ignoredAccountIds={ignoredAccountIds} />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={`${ESCROW_DETAIL_PAGE}/*`}
                            element={
                                <ProtectedRoute staffUser={staffUser} requiredRoles={[StaffUserRole.CLIENT_SERVICE]}>
                                    <EscrowPage staffUser={staffUser} />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={`${ABSOLUTE_KYS1_PAGE}/*`}
                            element={
                                <ProtectedRoute staffUser={staffUser} requiredRoles={[StaffUserRole.KYS1_REVIEWER]}>
                                    <Kys1Page user={user} staffUser={staffUser} />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={`${KYS2_PAGE}/*`}
                            element={
                                <ProtectedRoute staffUser={staffUser} requiredRoles={[StaffUserRole.ADMIN]}>
                                    <Kys2Page user={user} staffUser={staffUser} />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={`${FULL_AUDIT_PAGE}/*`}
                            element={
                                <ProtectedRoute staffUser={staffUser} requiredRoles={[StaffUserRole.KYS2_REVIEWER, StaffUserRole.CLIENT_SERVICE]}>
                                    <FullAuditRouter staffUser={staffUser} />
                                </ProtectedRoute>
                            }
                        />
                    </>
                )}
                <Route path={MAINTENANCE_PAGE} element={<MaintenancePage />} />
                <Route path={STAFF_USER_PROFILE_PAGE} element={<ProfilePage staffUser={staffUser} />} />
                <Route path="*" element={<Navigate to={ABSOLUTE_PRIVATE_ROOT_PAGE} />} />
            </Routes>
        </div>
    );
};

const PublicRoutes = () => {
    return (
        <Routes>
            <Route path={LOGIN_PAGE} element={<LoginPage />} />
            <Route path={REGISTER_PAGE} element={<RegisterPage />} />
            <Route path={PUBLIC_ROOT_PAGE} element={<LandingPage />} />
            <Route path="*" element={<Navigate to={PUBLIC_ROOT_PAGE || "/"} />} />
        </Routes>
    );
};

export default function AppRouter({ user, roles, staffUser }: { user?: User | null; roles: StaffUserRole[]; staffUser: StaffUser | null }) {
    return (
        <>
            {user && <PrivateRoutes user={user} roles={roles} staffUser={staffUser} />}
            {!user && <PublicRoutes />}
        </>
    );
}
