import {
    Account,
    calculateSectionScoreSummary,
    calculateSurveyScoreImpact,
    calculateSurveyScoreSummary,
    calculateVariantRecord,
    FullAudit,
    GradeColors,
    GRADES,
    isDefined,
    LANG_EN,
    mapScoreToGrade,
    queryFullAudit,
    Section,
    Survey,
    SurveyRecord,
    SurveyReport,
    SurveyVariantRecord
} from "@vaultinum/vaultinum-api";
import {
    BarChart,
    Button,
    Column,
    DEFAULT_GRADE_SCORE_RANGE,
    DownloadIcon,
    formatDate,
    getAccountsByIds,
    getFullAudit,
    getSurveyReports,
    openNotificationWithIcon,
    Spin,
    Table,
    useDebounce
} from "@vaultinum/vaultinum-sdk";
import dayjs from "dayjs";
import { countBy, difference, groupBy, isEqual, omit, uniq } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { AsyncActionButton, SurveyGradeScoreRange } from "../../../components";
import { buildCategoriesColumn, buildSectionsColumn, GradeScoreSummary } from "../../../helpers";
import { getSurveyLangsFromSurveyVersions } from "../../../services/surveyLangService";
import { getSurveyVersions, setSurveyGradeScoreRange } from "../../../services/surveyService";
import { IndustryAverage } from "./IndustryAverage";

const SCORE_SUMMARY_COLUMNS: Column<GradeScoreSummary>[] = [
    {
        header: "Creation Date",
        accessorKey: "recordCreationDate",
        cell: cell => formatDate(cell.getValue<Date>())
    },
    {
        header: "Completion time",
        accessorKey: "numberOfDaySinceCompletion",
        cell: cell => <>{cell.getValue<number>()} days</>
    },
    {
        header: "Company",
        accessorKey: "companyName"
    },
    {
        header: "Product name",
        accessorKey: "productName",
        cell: cell => cell.getValue<string>() || "-"
    },
    {
        header: "Version",
        accessorKey: "version"
    },
    {
        header: "Score",
        accessorKey: "score"
    },
    {
        header: "Grade",
        accessorKey: "grade"
    }
];

export function SurveyGradeScoreView({ survey }: { survey: Survey }): JSX.Element {
    const [surveyReports, setSurveyReports] = useState<SurveyReport[] | undefined>();
    const [surveyVersions, setSurveyVersions] = useState<Survey.Version[] | undefined>([]);
    const [gradeScoreRange, setGradeScoreRange] = useState<Survey.GradeScoreRange>(survey.gradeScoreRange || DEFAULT_GRADE_SCORE_RANGE);
    const [accounts, setAccounts] = useState<Account[] | undefined>();
    const [fullAudits, setFullAudits] = useState<FullAudit[] | undefined>();
    const [surveyLangs, setSurveyLangs] = useState<Survey.Lang[] | undefined>();

    useEffect(() => getSurveyVersions(survey.key, setSurveyVersions), [survey.key]);
    useEffect(() => getSurveyReports(setSurveyReports), []);

    const surveyRecords = useMemo(() => {
        const records = surveyReports
            ?.filter(element => element.surveyRecords.some(data => data.surveyKey === survey.key))
            .flatMap(report => report.surveyRecords.filter(record => record.surveyKey === survey.key));
        const groupRecords = groupBy(records, "id");
        return Object.values(groupRecords).map(records => {
            return records.sort((a, b) => a.creationDate.getTime() - b.creationDate.getTime())[0];
        });
    }, [surveyReports, survey.key]);

    const latestSurveyVersion = useMemo(() => {
        return surveyVersions?.find(version => version.latest);
    }, [surveyVersions]);

    const surveyLang = useMemo(() => {
        return surveyLangs?.find(lang => lang.surveyVersion === latestSurveyVersion?.version && lang.lang === LANG_EN);
    }, [surveyLangs, latestSurveyVersion]);

    useEffect(() => {
        void (async () => {
            try {
                const langs = await getSurveyLangsFromSurveyVersions(surveyVersions);
                setSurveyLangs(langs);
            } catch (e) {
                openNotificationWithIcon({ type: "error", message: "An error occured while retrieving the survey langs list" });
            }
        })();
    }, [surveyVersions]);

    useEffect(() => {
        void (async () => {
            if (surveyRecords?.length) {
                try {
                    const fullAuditList = (
                        await Promise.all(
                            uniq(surveyRecords.map(record => record.fullAuditId))
                                .filter(isDefined)
                                .map(fullAuditId => getFullAudit(fullAuditId))
                        )
                    ).filter(isDefined);
                    setFullAudits(fullAuditList);
                    const auditedAccountIds = uniq(
                        fullAuditList
                            .flatMap(fullAudit => (fullAudit ? queryFullAudit(fullAudit).getAccountIdsByRole(FullAudit.Role.AUDITED) : []))
                            .filter(isDefined)
                    );
                    const auditedAccounts = await getAccountsByIds(auditedAccountIds);
                    setAccounts(auditedAccounts.filter(account => !account.tags?.includes(Account.Tag.IGNORED)));
                } catch (e) {
                    openNotificationWithIcon({ type: "error", message: "An error occured while retrieving accounts linked to this survey" });
                }
            }
        })();
    }, [surveyRecords]);

    function mapScoreToSections(surveyVersion: Survey.Version, surveyVariantRecord: SurveyVariantRecord, surveyLang: Survey.Lang) {
        return surveyVersion.sections
            .map((section: Section) => {
                if (surveyLang.sections[section.id]?.title) {
                    return {
                        id: section.id,
                        label: surveyLang.sections[section.id].title,
                        score: calculateSectionScoreSummary(section, surveyVariantRecord)
                    };
                }
                return undefined;
            })
            .filter(isDefined);
    }

    const mapSurveyWithGrade = useCallback(
        (
            surveyRecord: SurveyRecord,
            surveyVersion: Survey.Version,
            scoreRange: Survey.GradeScoreRange,
            fullAudit: FullAudit,
            account: Account,
            surveyLang: Survey.Lang,
            lastSurveyVersion: Survey.Version
        ) => {
            const surveyVariantRecord = {
                ...surveyRecord,
                ...calculateVariantRecord(surveyVersion, surveyRecord)
            };
            const score = calculateSurveyScoreSummary(surveyVersion, surveyVariantRecord);
            const scoreImpact = calculateSurveyScoreImpact(surveyVersion, surveyVariantRecord);

            const tags = scoreImpact.survey.map(({ tag, score }) => ({ label: tag, score: Math.floor(score * 100) }));
            const missingTags = difference(
                lastSurveyVersion.evaluationTags,
                tags.map(element => element.label)
            );

            return {
                accountId: account.id,
                recordCreationDate: surveyVariantRecord.creationDate,
                numberOfDaySinceCompletion: dayjs(new Date()).diff(surveyVariantRecord.creationDate, "day"),
                score,
                version: surveyVersion.version,
                grade: mapScoreToGrade(score, scoreRange),
                companyName: account.companyName,
                productName: fullAudit.product.name,
                industry: account.companyIndustry,
                size: account.companySize,
                country: account.companyNationality,
                tags: [...tags, ...missingTags.map(element => ({ label: element, score: 0 }))],
                sections: mapScoreToSections(surveyVersion, surveyVariantRecord, surveyLang)
            };
        },
        []
    );

    const debouncedGradeScoreRange = useDebounce(gradeScoreRange, 500);
    const gradeScoreSummary = useMemo(() => {
        if (!surveyRecords || !surveyVersions || !accounts) {
            return undefined;
        }
        return surveyRecords
            .map(surveyRecord => {
                const surveyVersion = surveyVersions.find(element => element.version === surveyRecord.surveyVersion);
                const fullAudit = fullAudits?.find(fullAudit => fullAudit.id === surveyRecord.fullAuditId);
                if (!fullAudit) {
                    return undefined;
                }
                const auditedAccount = accounts.find(account => fullAudit.roles[account.id]?.includes(FullAudit.Role.AUDITED));
                if (surveyVersion && surveyLang && fullAudit && auditedAccount && latestSurveyVersion) {
                    return mapSurveyWithGrade(
                        surveyRecord,
                        surveyVersion,
                        debouncedGradeScoreRange,
                        fullAudit,
                        auditedAccount,
                        surveyLang,
                        latestSurveyVersion
                    );
                }
                return undefined;
            })
            .filter(isDefined);
    }, [debouncedGradeScoreRange, surveyRecords, surveyVersions, accounts, fullAudits, latestSurveyVersion, surveyLang, mapSurveyWithGrade]);

    const scores = useMemo(() => {
        if (gradeScoreSummary) {
            const letterWithCount = countBy(gradeScoreSummary, "grade");
            return GRADES.map(grade => letterWithCount[grade] || 0);
        }
        return [];
    }, [gradeScoreSummary]);

    async function publishScoreChange(): Promise<string> {
        await setSurveyGradeScoreRange(survey.key, gradeScoreRange);
        return "Score range was successfully updated";
    }

    function buildCSVHeader(gradeScoreSummary: GradeScoreSummary, surveyLang: Survey.Lang, latestSurveyVersion: Survey.Version) {
        const baseHeader = Object.keys(omit(gradeScoreSummary, ["sections", "categories", "tags"])).map(key => ({ label: key, key }));
        const tagsHeader: { label: string; key: string }[] = latestSurveyVersion.evaluationTags.map(tag => ({ label: tag, key: tag }));
        const sectionHeader = latestSurveyVersion.sections.map(section => {
            const title = surveyLang.sections[section.id]?.title || "";
            return {
                label: title,
                key: title
            };
        });
        return [...baseHeader, ...tagsHeader, ...sectionHeader];
    }

    function buildCSVData(gradeScoreSummary: GradeScoreSummary[], surveyLang: Survey.Lang, latestSurveyVersion: Survey.Version) {
        return gradeScoreSummary.map(element => {
            // Convert all sections and tags as object for csv
            const obj: { [key: string]: number | string } = {};
            element.tags.forEach(tag => (obj[tag.label] = tag.score));
            latestSurveyVersion.sections.forEach(section => {
                const foundSection = element.sections?.find(element => element.id === section.id);
                const title = surveyLang.sections[section.id]?.title;
                if (title) {
                    obj[title] = foundSection?.score || 0;
                }
            });
            return { ...element, ...obj };
        });
    }

    return (
        <div className="space-y-2">
            <div className="flex justify-end">
                <AsyncActionButton onClick={publishScoreChange} isDisabled={isEqual(survey.gradeScoreRange, gradeScoreRange)}>
                    Publish score change
                </AsyncActionButton>
            </div>
            <div className="flex gap-2">
                <div className="w-1/2 space-y-10 rounded-md p-5 shadow-md">
                    <h3>Score to grade mapping</h3>
                    {!gradeScoreRange && <Spin />}
                    <SurveyGradeScoreRange scoreRange={gradeScoreRange} onUpdate={setGradeScoreRange} />
                </div>
                <div className="flex w-1/2 flex-col justify-between rounded-md p-5 shadow-md">
                    <h3>Live reference chart</h3>
                    {(!gradeScoreRange || !gradeScoreSummary) && <Spin />}
                    {gradeScoreRange && gradeScoreSummary && (
                        <>
                            <BarChart
                                titles={GRADES}
                                dataset={[
                                    {
                                        label: "Score",
                                        data: scores
                                    }
                                ]}
                                colors={GRADES.map(grade => GradeColors[grade])}
                                yMaxValue={100}
                                legendPosition="none"
                            />
                            <p className="p-2 font-medium">Based on {gradeScoreSummary?.length} records</p>
                        </>
                    )}
                </div>
            </div>
            <>
                <div className="flex flex-col gap-5 rounded-md p-5 shadow-md">
                    {(!gradeScoreSummary || !latestSurveyVersion || !surveyLang) && <Spin />}
                    {gradeScoreSummary && latestSurveyVersion && surveyLang && (
                        <>
                            <IndustryAverage
                                surveyKey={survey.key}
                                gradeScoreSummary={gradeScoreSummary}
                                latestSurveyVersion={latestSurveyVersion}
                                surveyLang={surveyLang}
                            />
                            <h3>Survey record details ({gradeScoreSummary.length})</h3>
                            {!!gradeScoreSummary.length && (
                                <div className="flex items-center justify-end">
                                    <CSVLink
                                        filename={survey.name}
                                        headers={buildCSVHeader(gradeScoreSummary[0], surveyLang, latestSurveyVersion)}
                                        data={buildCSVData(gradeScoreSummary, surveyLang, latestSurveyVersion)}
                                        separator=","
                                    >
                                        <Button isLoading={false} icon={DownloadIcon} children="Download CSV" />
                                    </CSVLink>
                                </div>
                            )}
                            <div className="h-96 overflow-hidden">
                                <Table<GradeScoreSummary>
                                    data={gradeScoreSummary}
                                    columns={[
                                        ...SCORE_SUMMARY_COLUMNS,
                                        ...buildCategoriesColumn<GradeScoreSummary>(latestSurveyVersion),
                                        ...buildSectionsColumn<GradeScoreSummary>(latestSurveyVersion.sections, surveyLang)
                                    ]}
                                />
                            </div>
                        </>
                    )}
                </div>
            </>
        </div>
    );
}
